<template>
  <!-- 贴身毛详情 -->
  <div>
    <div class="notice-cont">
      <div class="notice-list-cont">
        <div class="notice-list-head f-c">
          <div class="f-c" style="margin-left: 55px;flex: 1;">
            <img class="head-img" src="../../assets/img/foot.png" alt="">
            <span class="head-title">贴身毛</span>
          </div>
          <div class="black f-c">
            <img src="../../assets/img/black.png" alt="">
            <span>返回</span>
          </div>
          <div class="notice-head-line"></div>
        </div>
        <div class="notice-lists">
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'noticeDetail',
  data(){
    return{

    }
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
<style scoped lang="less">
.line{
    width: 2px;
    height: 20px;
    background: #E9B96B;
    margin-left: 20px;
}
.head{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #24242D;
}
.head-img{
    width: 40px;
    height: 40px;
    margin-right: 35px;
}
.head-cont{
    display: flex;
    align-items: center;
}
.head-cont span{
    font-size: 20px;
    color: #636378;
    margin-left: 20px;
    cursor: pointer;
}
.head-cont span:hover{
    color: #B7B7DE;
}
.notice-cont{
  /* display: flex; */
  position: fixed;
  background: url(../../assets/img/bg.jpg)center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.notice-list-cont{
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 80px;
}
.notice-list-head{
  position: relative;
  height: 150px;
  border-radius: 25px 25px 0px 0px;
  background: linear-gradient(180deg, rgba(233, 185, 107, 0.1) 0%, rgba(233, 185, 107, 0) 99%), #24242D;
  .head-img{
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }
  .head-title{
    font-size: 30px;
    color: #E9B96B;
  }
}
.notice-head-line{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(270deg, rgba(233, 185, 107, 0) 0%, #E9B96B 51%, rgba(233, 185, 107, 0) 100%);
}
.notice-lists{
  width: 1200px;
  background: #24242D;
  padding: 35px 35px 75px 35px;
  box-sizing: border-box;
  margin-bottom: 170px;
  border-radius: 0 0 25px 25px;
}
.f-c{
  display: flex;
  align-items: center;
}
.txt-p{
  font-size: 30px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 20px;
}
.black{
  width: 100px;
  height: 50px;
  border-radius: 8px;
  background: #E9B96B;
  margin-right: 36px;
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
  img{
    width: 24px;
    margin-right: 10px;
  }
  span{
    color: #101015;
    font-size: 18px;
  }
}
</style>